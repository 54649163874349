import { createContext, useEffect, useState } from 'react';
import { motion, useMotionValue, Variants, animate, useTransform } from 'framer-motion';


const AnimationContext = createContext();

export const AnimationProvider = ({children}) => {
  const [colorOne, setColorOne] = useState('#1db954');
  const [colorTwo, setColorTwo] = useState('#9b24dc');
  const [scoreVisible, setScoreVisible] = useState(false);

  const rotateLogo = async () => {
    await animate('.logo', {rotate: [0,-135]}, {type: "spring", duration: 0.5});
    animate('.logo', {rotate: [-135,360]}, {type: "spring", duration: 1});
  }
  const slideScoreIn = () => {
    setScoreVisible(true);
    animate('.score', {
      x: ["-50%", "0%"], 
      opacity: [0,1]
    }, {type: "spring", delay: 0.5, duration: 1});
  }

  const progressArray = [
    `linear-gradient(to right, #795AA5 0%, ${colorOne} 0%)`,
    `linear-gradient(to right, #795AA5 100%, ${colorOne} 100%)`
  ]
  const countDownValue = useMotionValue(10);
  const roundedValue = useTransform(countDownValue, Math.round);
  const progressAnimation = () => {
    animate('.next-track-btn', {backgroundImage: progressArray},{
      type: "linear",
      duration: 10
    })
    animate(countDownValue, [10,0], {duration: 10});
    countDownValue.set(10);

  }

  const loopArray = [];
  const rawTimesArray = [];
  let timeIncrement = 0;
  let switched = false;
  for(let i=1;i<=72;i++){
    let deg = i * 22.5;
    if(i % 4 == 0){
      switched = !switched;
      timeIncrement+=3;
    }
    rawTimesArray.push(timeIncrement);
    timeIncrement++;
    if(!switched){
      loopArray.push(
        `linear-gradient(
          ${deg}deg, 
          ${colorOne} -50%, 
          ${colorTwo} 150%)`
      );
    }else{
      loopArray.push(
        `linear-gradient(
          ${deg}deg, 
          ${colorTwo} -60%, 
          ${colorOne} 160%)`
      );
    }
  }
  const lastFrame = rawTimesArray.slice(-1);
  const formattedTimes = rawTimesArray.map((time) => time / lastFrame);
 
  const beginGradientAnimate = (target, property) => {

    if(document.querySelector(target)){
      animate(target, {backgroundImage: loopArray}, {
        type: "linear", 
        duration: lastFrame/3, 
        repeat: Infinity,
        times: formattedTimes
      });
    }
  }

  const gradientVariants = {
    animatedGradient: {
      backgroundImage: loopArray,
      transition: {
        type: "linear",
        duration: lastFrame/3,
        repeat: Infinity,
        times: formattedTimes
      }
    },
    animationEnded: {
      backgroundImage: 'unset'
    }
  }


  return <AnimationContext.Provider value={{
    colorOne,
    colorTwo,
    rotateLogo,
    slideScoreIn,
    scoreVisible,
    beginGradientAnimate,
    progressAnimation,
    gradientVariants,
    roundedValue
  }}>
    {children}
  </AnimationContext.Provider>
}

export default AnimationContext