import { useEffect, useRef, useContext } from 'react';
import {motion, animate, useAnimate, useAnimationFrame } from 'framer-motion';
import AnimationContext from '../context/animation/AnimationContext';


function AnimateGradient() {
  const {
    beginGradientAnimate,
    colorOne,
    colorTwo
  } = useContext(AnimationContext);
  return (
    <svg width="0" height="0">
    <motion.linearGradient id="animatedGradient" x1="100%" y1="100%" x2="0%" y2="0%">
      <stop offset="0%" stopColor={`${colorOne}`} />
      <stop offset="100%" stopColor={`${colorTwo}`} />
    </motion.linearGradient>
    </svg>
  )
}
export default AnimateGradient