import { Link } from "react-router-dom"
function Footer() {
  return (
    <footer className="footer bg-neutralDark footer-center gap-0 z-20">
      <nav className="navbar justify-center my-2 flex space-between">
        <Link to='mailto:m.a.segaric@gmail.com' className='hover:text-primary'>Contact me</Link>
        <p>|</p>
        <Link to='/inprogress' className='hover:text-primary'>My Portfolio</Link>
      </nav>
      <p className='text-1xl my-2'>Copyright &copy; Mark Segaric 2023</p>
    </footer>
  )
}
export default Footer