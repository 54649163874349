import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Profile from './pages/Profile';
import InProgress from './pages/InProgress';
import NotFound from './pages/NotFound';
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import { UserAuthProvider } from './context/UserAuth/UserAuthContext';
import { GameProvider } from './context/Game/GameContext';
import { AnimationProvider } from './context/animation/AnimationContext';

import './App.css';
function App() {

  const closeMenu = () => {
    const menuContainer = document.querySelector('.dropdown');
    if(menuContainer.hasAttribute('open')){
      menuContainer.removeAttribute('open');
    }
  }
  return (
    <UserAuthProvider>
      <GameProvider>
        <AnimationProvider>
        <Router>
          <div className="flex flex-col justify-between h-screen bg-neutralLight"
          onClick={closeMenu}>
            <Navbar/>
            <main className='grow sm:w-full md:w-3/4 lg:w-1/2 m-auto p-5'>
              <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/profile' element={<Profile/>}/>
                <Route path='/inprogress' element={<InProgress/>}/>
                <Route path='/*' element={<NotFound/>}/>
              </Routes>
            </main>
            <Footer/>
          </div>
        </Router>
        </AnimationProvider>
      </GameProvider>
    </UserAuthProvider>
  );
}

export default App;
