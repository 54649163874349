import { useContext, useEffect, useState } from 'react';
import { motion, animate, AnimatePresence } from 'framer-motion';
import GameContext from '../../context/Game/GameContext';
import UserAuthContext from '../../context/UserAuth/UserAuthContext';
import AnimationContext from '../../context/animation/AnimationContext';
import TrackOptionButton from './TrackOptionButton';
import {HiPlayPause} from 'react-icons/hi2';
import LoadingScreen from './LoadingScreen';
import GameTrackInfo from './GameTrackInfo';
import GameHeader from './GameHeader';

const GameContainer = () => {
  const {
    loading,
    setLoading,
    fetchToken,
    fetchUserData,
    accessToken,
    user
  } = useContext(UserAuthContext);

  const {
    tracks,
    currentTrack,
    setCurrentTrack,
    fetchTracks,
    gameStatus,
    gameLoading,
    handlePlayPause,
    setGameWon,
    gameStarted
  } = useContext(GameContext); 

  const {beginGradientAnimate, endGradientAnimate} = useContext(AnimationContext);

  useEffect(() => {
    if(gameStatus === 'gameWon'){
      setTimeout(()=>{
        beginGradientAnimate('.play-button', 'backgroundImage');
        beginGradientAnimate('.track-details', 'backgroundImage');
      },600)
    }
  },[gameStatus])

  useEffect(() => {
    if(accessToken){
      fetchTracks(accessToken);
    }
  },[])

  useEffect(() => {
    if(tracks.length > 0){
      setLoading(true);
      const trackToPlay = tracks[tracks.length * Math.random() | 0];
      setCurrentTrack(trackToPlay);
      setLoading(false);
    }
  },[tracks])

  return (
    <AnimatePresence mode="wait">
      <motion.div className='game-container z-10 flex flex-col h-full'>
      { user == null || currentTrack == null || loading  || gameLoading ? <LoadingScreen /> : 
        <>
          <div className="h-1/2 md:h-3/4 grow flex flex-col justify-evenly">
            <AnimatePresence mode="wait">
              <motion.div
              key={gameStarted ? currentTrack.name : "header"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: "easeInOut" }}
              className='flex-1 flex items-center'
              >
                {gameStatus === 'welcome' ? <GameHeader/> : <GameTrackInfo/> }
              </motion.div>
            </AnimatePresence>
            <motion.button 
              onClick={handlePlayPause} 
              className="play-button btn bg-slate-900 rounded-full text-neutralLight border-none mb-2 mx-auto hover:bg-primary hover:text-neutralDark">
              <HiPlayPause />
            </motion.button>
            <video controls={false} name='media' playsInline src={currentTrack.preview_url} className='current-track text-center hidden'></video>
          </div>
          <AnimatePresence mode='wait'>
          <motion.div
            layout
            className='h-1/3 md:h-1/4 mb-3 text-center flex items-center'
            key={gameStatus === 'gameWon' ? currentTrack.artists[0].name : "tracks"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: 'easeIn' }}>
            {gameStatus === 'gameWon' ? 
            <motion.h1
            layout
            className="flex-1 track-details text-transparent bg-clip-text inline-block text-center font-bold text-3xl">
              {currentTrack.artists[0].name} 
              <br/> {currentTrack.name}
            </motion.h1> 
            :  
            <motion.div
            layout 
            id='trackOptionContainer' 
            className="flex-1 grid grid-rows-2 md:grid-cols-2 sm:grid-cols-1 gap-1">
              {tracks.map((track, i) => 
              <TrackOptionButton isAnswer={track == currentTrack} title={track.name} artistName={track.artists[0].name} trackid={tracks.indexOf(track)} key={i} index={i} />
              )}
            </motion.div> 
          }
          </motion.div>
          </AnimatePresence>
        </>
      }
      </motion.div>
    </AnimatePresence>
  )
}
export default GameContainer