import React, {useState, useContext, useEffect} from 'react';
import { AnimatePresence, motion, useMotionValue, useTransform, animate, Variants } from 'framer-motion';
import UserAuthContext from '../../context/UserAuth/UserAuthContext';
import GameContext from '../../context/Game/GameContext';
import AnimationContext from '../../context/animation/AnimationContext';
import { Link } from 'react-router-dom'
import {ImSpotify } from 'react-icons/im'
import { RiMenu4Line } from "react-icons/ri";
import {BiSolidUserCircle} from 'react-icons/bi';
import TimeoutModal from '../TimeoutModal';
import NextTrackButton from './NextTrackButton';
import AnimateGradient from '../AnimateGradient';

function Navbar({title}) {

  const { user } = useContext(UserAuthContext);
  const { 
    handlePlayAgain,
    score,
    gameStatus
  } = useContext(GameContext);
  const {
    colorOne,
    slideScoreIn,
    rotateLogo,
    scoreVisible,
    progressAnimation,
    countDownValue
  } = useContext(AnimationContext);

  useEffect(() => {
    if(gameStatus === 'gameWon') rotateLogo();
  },[gameStatus])
  useEffect(() => {
    if(gameStatus == 'gameWon'){
      if(!scoreVisible){
        //beginGradientAnimate('.logo', 'fill');
        slideScoreIn();
      } 
      setTimeout(() => {
        progressAnimation();
      }, 1000)
    }
  },[gameStatus, scoreVisible])

  return (
    <nav className="navbar bg-neutralDark z-20 shadow-custom">
      <div 
        className="logo m-2">
        <AnimateGradient/>
        <Link to='/'>
        <ImSpotify size={49} style={gameStatus == "gameWon" ? { fill: "url(#animatedGradient)" } : { fill: colorOne}}/>
        </Link>
      </div>
      <AnimatePresence>
      <motion.div 
          initial={{x: "-50%", opacity: 0}}
          className="score flex-col ml-4">
      <>
      <div 
        className='text-center text-neutralLight text-xl'>
          Score: <motion.span 
                  key={score}
                  animate={{opacity: [0,1]}} 
                  transition={{duration: 0.5, delay: 0.5, ease: "easeInOut"}}>{score}</motion.span>
          </div>
        <NextTrackButton/>
        </>
      </motion.div>
      </AnimatePresence>
      <TimeoutModal/>
      <div className="mx-2 flex-1 justify-end">
        <details className="dropdown dropdown-bottom dropdown-end">
          <summary className="m-1 btn p-0"><RiMenu4Line size={35}/></summary>
          <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52">
            <li>
              <Link to='/' className='btn btn-sm btn-ghost rounded-full'>
                Home
              </Link>
            </li>
            <li>
              <Link to='/about/' className='btn btn-sm btn-ghost rounded-full'>
                About
              </Link>
            </li>
            <li>
            <Link to='/profile/' className='btn btn-ghost rounded-full'>
                Profile
            </Link>
            {/* <Link to='/profile/' className='btn btn-ghost btn-circle ml-5 p-1 hover:bg-primary hover:text-neutralDark'>
              {user && user.images ? <img src={user.images[1].url} className='rounded-full'></img>
                    : <BiSolidUserCircle size={35} />
                }
                Profile
            </Link> */}
            </li>
          </ul>
        </details>
      </div>
    </nav>
  )
}
Navbar.defaultProps = {
  title: "Spotify Music Quiz"
}
export default Navbar
