import { useContext, useState, useEffect, useRef } from 'react';
import { motion, animate } from 'framer-motion';
import GameContext from '../../context/Game/GameContext';
import AnimateGradient from '../AnimateGradient';

function TrackOptionButton({ title, artistName, isAnswer, trackid, index}) {
  const [trackShown, setTrackShown] = useState(false);
  const [incorrect, setIncorrect] = useState(false);

  const {
    tracks,
    currentTrack,
    gameWon,
    setGameWon,
    gameStatus,
    setGameStatus,
    score,
    setScore,
    points,
    setPoints,
    gameActive
  } = useContext(GameContext);

  const variants = {
    show: {
      opacity: 1,
      scale: 1,
      transition: {delay: 0.75 + index/4}
    },
    hide: { 
      opacity: 0, 
      scale: 0,
      transition: {duration: 0.75}
    },
    incorrect: {
      opacity: 0.5,
      backgroundColor: 'red'
    }
  };  



  useEffect(() => {
    //gameActive ? setTrackShown(true) : setTrackShown(false);
    gameStatus == 'gameStarted' ? setTrackShown(true) : setTrackShown(false);
  },[gameStatus])

  
  const handleGuess = (id) => {
    if(id == tracks.indexOf(currentTrack)){
      //setGameWon(true);
      setGameStatus('gameWon');
      let newScore = score + points;
      setScore(newScore);
    }else{
      setPoints(points - 1);
      setTrackShown(false);
    }
  }

  return (
    <motion.button
      variants={variants}
      initial={false}
      animate={trackShown ? "show" : "hide"}
      onClick={() => {handleGuess(trackid)}}
      key={index}
      trackid={trackid}
      className="btn btn-sm rounded-full border-none bg-neutralDark text-neutralLight hover:bg-secondary hover:text-neutralDark" 
      disabled={!trackShown}
    >
    {title}
    </motion.button>
  )
}
export default TrackOptionButton