import { useState, useEffect, useContext } from 'react';
import AuthScreen from '../components/layout/AuthScreen';
import LoadingScreen from '../components/layout/LoadingScreen';
import UserProfile from '../components/layout/UserProfile';
import UserAuthContext from '../context/UserAuth/UserAuthContext';

function Profile() {
  const { 
    loading,
    setLoading,
    fetchToken,
    accessToken,
    verifierCode,
    setVerifierCode,
    login,
    user,
    fetchUserData
  } = useContext(UserAuthContext);

  return (
    <div className='text-left flex flex-col h-full justify-center'>
        { user ? <UserProfile/> : <AuthScreen/> }
    </div>
  )
}
export default Profile