import { useState, useContext, useEffect } from 'react';
import UserAuthContext from '../context/UserAuth/UserAuthContext';

function TimeoutModal() {
  const {
    timeRemaining,
    setTimeRemaining,
    checkRemaining,
    accessToken,
    verifierCode,
    fetchToken,
    fetchUserData,
    setVerifierCode,
    login,
    logout,
    setLoading
  } = useContext(UserAuthContext);

  useEffect(() => {
    if(verifierCode){
      const urlParams = new URLSearchParams(window.location.search);
      let code = urlParams.get('code');
    
      let body = new URLSearchParams({
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        client_id: process.env.REACT_APP_CLIENT_ID,
        code_verifier: verifierCode
      });
      fetchToken(body);
      setVerifierCode(null);
      localStorage.removeItem('code_verifier');
    }
  },[verifierCode])

  useEffect(() => {
    if(accessToken !=null){
      fetchUserData();
    }
  },[accessToken])
  useEffect(() => {
    if(timeRemaining && timeRemaining < 120){
      console.log(timeRemaining);
      document.querySelector('.timeout-container').classList.remove('hidden');
    }
    if(timeRemaining && timeRemaining <= 0){
      logout();
    }
  },[timeRemaining])
  useEffect(() => {
    const interval = setInterval(() => {
      if(timeRemaining != null){
        setTimeRemaining(checkRemaining);
        clearInterval(interval);
      }
    },1000)
  })  
  const handleModalClick = () => {
    console.log('line 62 test');
    setLoading(true);
    logout();
    login();
    setLoading(false);
  }
  const handleBackgroundClick = () => {
    logout();
  }

  return (
    <div className='timeout-container hidden'>
      <div className="modal-background absolute bg-neutralDark h-100 w-100 inset-0 z-10 opacity-70 hover:opacity-60"
           onClick={handleBackgroundClick}></div>
      <div className="modal-box bg-neutralLight absolute left-1/2 -translate-x-1/2 top-1/4 opacity-100 z-20">
        <h3 className="font-bold text-lg text-neutralDark">Oops!</h3>
        <span className="mt-4 text-neutralDark">It looks like your Spotify authorization ran out of time. 
        Press "Okay" to login again, or tap/click anywhere else to log out.</span>
        <div className="modal-action">
            <button onClick={handleModalClick}className="btn bg-neutralDark">Okay</button>
        </div>
      </div>
    </div>

  )
}
export default TimeoutModal