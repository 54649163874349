function About() {
  return (
    <div className="text-left">
    <div className="card shadow bg-neutralLight rounded-lg flex flex-row">
      <div className='my-auto flex-1 p-5'>
        <h2 className='text-neutralDark text-2xl mb-3'>About</h2>
        <p className='text-neutralDark'>This web-app was built with ReactJS, and integrates with 
           Spotify's free API to serve users a portion of a random song from their top tracks. 
           Users can then guess what the song is to score points.</p>
      </div>
    </div>
   </div>
  )
}

export default About
