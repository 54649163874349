import { createContext, useEffect, useState } from 'react';

const UserAuthContext = createContext();

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT;
const RESPONSE_TYPE = process.env.REACT_APP_RESPONSE_TYPE;

console.log('env redirect URI: ',process.env.REACT_APP_REDIRECT_URI);
//console.log('env local redirect URI: ',process.env.local.REACT_APP_REDIRECT_URI);

export const UserAuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [verifierCode, setVerifierCode] = useState(localStorage.getItem('code_verifier'));
  const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token')); 
  const [timeRemaining, setTimeRemaining] = useState(checkRemaining);
  const userDataUrl = 'https://api.spotify.com/v1/me';

  function checkRemaining(){
    if(localStorage.getItem('expiry_time')){
      return Math.floor((localStorage.getItem('expiry_time') - Date.now())/1000);
    }else{
      return null;
    }
  }
  const updateTime = () => {
    setTimeRemaining(checkRemaining);
  }

  //fetches the data from Spotify once we have an auth token to use
  const fetchUserData = async () => {
    setLoading(true);
    const response = await fetch(`${userDataUrl}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    
    const data = await response.json();
    console.log(data);
    setUser(data);
    setLoading(false);
  }

  //generates random string to use for the the creation of the code challenge
  const generateRandomString = (length) => {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  //generates a code challenge, which is used during the process of the user logging in to generate an auth token
  const generateCodeChallenge = async (codeVerifier) => {
    function base64encode(string) {
      return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    }
  
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
  
    return base64encode(digest);
  }

  //login function
  const login = () => {
    let codeVerifier = generateRandomString(128);
  
    generateCodeChallenge(codeVerifier).then(codeChallenge => {
      let state = generateRandomString(16);
      let scope = 'user-read-private user-read-email streaming user-top-read user-library-read';
      
      localStorage.setItem('code_verifier', codeVerifier);
      let args = new URLSearchParams({
        response_type: 'code',
        client_id: CLIENT_ID,
        scope: scope,
        redirect_uri: REDIRECT_URI,
        state: state,
        code_challenge_method: 'S256',
        code_challenge: codeChallenge
      });
      window.location = 'https://accounts.spotify.com/authorize?' + args;
    });
  } 
  //logout function
  const logout = () => {
    localStorage.removeItem('expiry_time');
    document.querySelector('.timeout-container').classList.add('hidden');
    localStorage.removeItem('access_token');
    setTimeRemaining(null);
    setAccessToken(null);
    setUser(null);
    setLoading(false);
  }
  //fetches the Spotify access token if an auth code is found, then removes 
  //unnecessary locally stored items once the token is set
  const fetchToken = async () => {
    if(verifierCode){
      const urlParams = new URLSearchParams(window.location.search);
      let code = urlParams.get('code');

      let body = new URLSearchParams({
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: REDIRECT_URI,
        client_id: CLIENT_ID,
        code_verifier: verifierCode
      });
      console.log(body.code_verifier);
      const response = await fetch('https://accounts.spotify.com/api/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: body
      })
      const data = await response.json();
      console.log(data);
      if (!response.ok) return; //todo: handle error here
      localStorage.setItem('access_token', data.access_token);
      //data.expires_in is the amount of time (in seconds) remaining before the token expires
      //let's add it to the current time (converted to seconds) and store it so that we 
      //know when to make our users re-auth
      localStorage.setItem('expiry_time', Date.now() + (data.expires_in * 1000));
      setTimeRemaining(checkRemaining);
      setAccessToken(data.access_token);
      setVerifierCode(null);
      localStorage.removeItem('code_verifier');
    }
  }

  return <UserAuthContext.Provider value={{
    user,
    setUser,
    userDataUrl,
    loading,
    setLoading,
    fetchToken,
    verifierCode,
    setVerifierCode,
    accessToken,
    setAccessToken,
    fetchUserData,
    login,
    logout,
    timeRemaining,
    setTimeRemaining,
    checkRemaining,
    generateRandomString,
    generateCodeChallenge
  }}>
    {children}
  </UserAuthContext.Provider>
}
export default UserAuthContext