import { useContext, useEffect, useState } from 'react';
import UserAuthContext from '../../context/UserAuth/UserAuthContext';
import {BiUserCircle} from 'react-icons/bi';

function UserProfile() {
  const { 
    user,
    accessToken,
    loading,
    setLoading,
    logout
  } = useContext(UserAuthContext);

  return (
    <div className="text-center">
    <div className="card shadow bg-neutral rounded-lg flex flex-row">
      <div className="m-3 flex-none text-center border">
        <h2 className='mt-3'>@{user.id}</h2>
      </div>
      <div className='my-auto flex-1'>
        <h2 className='text-2xl'>{user.display_name}</h2>
      </div>
    </div>
    <button onClick={logout} className="btn rounded-btn bg-neutral hover:bg-primary m-2">Log out</button>
   </div>
  )
}
export default UserProfile