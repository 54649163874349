import { createContext, useState, useReducer } from 'react';
import gameReducer from './GameReducer';
import { motion, animate } from 'framer-motion';


const GameContext = createContext();

export const GameProvider = ({children}) => {
  const initialState = {
    tracks: [],
    currentTrack: null,
    loading: false,
    gameStatus: 'welcome',
    gameStarted: false,
    gameActive: false,
    gameWon: false,
    score: 0,
    points: null
  }
  const [state, dispatch] = useReducer(gameReducer, initialState);

  //const [tracks, setTracks] = useState([]);
  //const [currentTrack, setCurrentTrack] = useState(null);
  //const [loading, setLoading] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [gameActive, setGameActive] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);



  //Fisher-Yates shuffle - used for randomizing our tracks before setting the active track and the options before using them
  const shuffle = (array) => {
    let oldElement;
    for (let i = array.length - 1; i > 0; i--) {
      let rand = Math.floor(Math.random() * (i + 1));
      oldElement = array[i];
      array[i] = array[rand];
      array[rand] = oldElement;
    }
    return array;
  }
  const setPoints = (integer) => {
    dispatch({
      type: 'SET_POINTS',
      payload: integer
    })
  } 
  const setCurrentTrack = (track) => {
    dispatch({
      type: 'SET_CURRENT_TRACK',
      payload: track
    })
  }
  const setScore = (score) => {
    dispatch({
      type: 'SET_SCORE',
      payload: score
    })
  }
  const setGameStatus = (status) => {
    dispatch({
      type: 'SET_GAME_STATUS',
      payload: status
    })
    console.log(state.gameStatus);
  }
  const fetchTracks = async (token) => {
    const timeRanges = ["short_term","medium_term","long_term"];
    //const rangeToUse = shuffle(timeRanges)[0];
    const rangeToUse = "long_term";
    const response = await fetch(`https://api.spotify.com/v1/me/top/tracks?time_range=${rangeToUse}&limit=50`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    const data = await response.json();
    const tracks = shuffle(data.items.filter((item) => item.preview_url != null));
    //console.log(tracks);
    setTimeout(() => {
      dispatch({
        type: 'SET_TRACKS',
        payload: tracks.slice(0,4)
      })
      dispatch({
        type: 'SET_GAME_LOADING',
        payload: false
      })
      setPoints(3);
      return tracks;
    }, 750)
  }

  const handlePlayPause = () => {
    const currentTrackEl = document.querySelector('.current-track');
    if(currentTrackEl){
      if (currentTrackEl.paused) {
        currentTrackEl.play();
        if(state.gameStatus == 'welcome' || state.gameStatus == 'notStarted'){
          setGameStatus('gameStarted');
        }
        if(!gameStarted) setGameStarted(true);
        if(!gameActive) setGameActive(true);
      }else{
        currentTrackEl.pause();
      }
    }
  }
  const handlePlayAgain = () => {
    setGameWon(false);
    setGameStatus('notStarted');
    if(!document.querySelector('.current-track').paused){
      document.querySelector('.current-track').pause();
    }
    setGameActive(false);
    dispatch({
      type: 'SET_GAME_LOADING',
      payload: true
    })
      fetchTracks(localStorage.getItem('access_token'));
  }

  return <GameContext.Provider value={{
    tracks: state.tracks,
    currentTrack: state.currentTrack,
    setCurrentTrack,
    fetchTracks,
    handlePlayPause,
    setGameStatus,
    gameStatus: state.gameStatus,
    gameWon,
    setGameWon,
    handlePlayAgain,
    gameStarted,
    setGameStarted,
    gameActive,
    setGameActive,
    score: state.score,
    setScore,
    points: state.points,
    gameLoading: state.gameLoading,
    setPoints
  }}>
    {children}
  </GameContext.Provider>

}

export default GameContext