import { useContext, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import UserAuthContext from '../../context/UserAuth/UserAuthContext';


function GameHeader() {
  const {
    user
  } = useContext(UserAuthContext);

    return (
    <AnimatePresence>
        <motion.div
          className="text-neutral text-left z-10 sm:mx-1 md:mx-2">
            <motion.h2 
              initial={{opacity: 0}}
              animate={{opacity: [0,1]}}
              transition={{ duration: 0.5, delay: 1 }}
              className='font-bold text-2xl md:text-4xl wrap welcome-heading mb-2'>Hey {user.display_name.split(' ')[0]}!</motion.h2>
              <motion.p 
              initial={{opacity: 0}}
              animate={{opacity: [0,1]}}
              transition={{ duration: 0.5, delay: 1.75 }}
              className='font-regular text-md md:text-xl wrap welcome-body'>Welcome to the Spotify Music Quiz web-app. 
              We'll play you a snippet of a song from one of your saved playlists. 
              Have a listen, and see if you can guess what it is!</motion.p>
        </motion.div>
    </AnimatePresence>
    ) 
  
  

}

export default GameHeader