const GameReducer = (state, action) => {
  switch(action.type){
    case 'SET_TRACKS':
      return {
        ...state,
        tracks: action.payload
      }
    case 'SET_GAME_LOADING':
      return {
        ...state,
        gameLoading: action.payload
      }
    case 'SET_POINTS':
      return {
        ...state,
        points: action.payload
      }
    case 'SET_CURRENT_TRACK': 
      return {
        ...state,
        currentTrack: action.payload
      }
    case 'SET_SCORE': 
      return {
        ...state,
        score: action.payload
      }
    case 'SET_GAME_STATUS':
      return {
        ...state,
        gameStatus: action.payload
      }
    default: 
      return state;
  }
}

export default GameReducer;