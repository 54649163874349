import { useContext, useEffect, useState } from 'react';
import UserAuthContext from '../context/UserAuth/UserAuthContext';
import GameContext from '../context/Game/GameContext';
import AuthScreen from '../components/layout/AuthScreen';
import LoadingScreen from '../components/layout/LoadingScreen';
import GameContainer from '../components/layout/GameContainer';

function Home() {
  const {
    currentTrack
  } = useContext(GameContext);
  const {
    setLoading,
    fetchUserData,
    accessToken,
    user
  } = useContext(UserAuthContext);

  useEffect(() => {
    if(accessToken != null && !user){
      fetchUserData();
    }
  },[accessToken])


  return (
    <div className="text-left flex flex-col h-full justify-center">
       {accessToken ? <GameContainer/> : <AuthScreen/>}
    </div>
  )
}

export default Home
