import React, {useState, useContext, useEffect} from 'react';
import { AnimatePresence, motion, useMotionValue, useTransform, animate, Variants } from 'framer-motion';
import UserAuthContext from '../../context/UserAuth/UserAuthContext';
import GameContext from '../../context/Game/GameContext';
import AnimationContext from '../../context/animation/AnimationContext';

function NextTrackButton() {
  const { user } = useContext(UserAuthContext);
  const { 
    handlePlayAgain,
    gameStatus
  } = useContext(GameContext);
  const {
    progressAnimation,
    roundedValue
  } = useContext(AnimationContext);

  useEffect(() => {
    if(gameStatus == 'gameWon'){
      setTimeout(() => {
        progressAnimation();
        setTimeout(() => {
          handlePlayAgain();
        }, 10000)
      }, 1000)
    }
  },[gameStatus])
  return (
    <motion.button 
    onClick={handlePlayAgain} 
    className='next-track-btn btn btn-sm 
              bg-primary text-neutralLight
              rounded-full hover:text-neutralDark hover:bg-primary'>
      Next track in <motion.span>{roundedValue}</motion.span>
    </motion.button>
  )
}
export default NextTrackButton