import { motion, AnimatePresence } from 'framer-motion';
import { useContext } from 'react';
import GameContext from '../../context/Game/GameContext';
import {ReactComponent as QuestionBox} from '../../assets/box.svg';

function GameTrackInfo() {

  const { gameStatus, currentTrack } = useContext(GameContext); 

  return (
        <AnimatePresence mode='wait'>
          <motion.div
          key={gameStatus === 'gameWon' ? currentTrack.name : "question"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: .75, type: "spring" }}
          className='rounded-sm mx-auto current-track__info'>
            {gameStatus === 'gameWon' ?
            <img 
                src={currentTrack.album.images[0].url} 
                alt={currentTrack.name} 
                className='w-40 mx-auto rounded-lg shadow-custom shadow-neutralDark'
            />
            : <QuestionBox 
              className='w-40 mx-auto rounded-lg fill-slate-900'/>}
          </motion.div>          
        </AnimatePresence>
  )
}
export default GameTrackInfo