import { useContext, useEffect, useState } from 'react';
import UserAuthContext from '../../context/UserAuth/UserAuthContext';
import LoadingScreen from './LoadingScreen';

function AuthScreen() {
  const {
    login, 
    loading, 
    setLoading, 
    accessToken
  } = useContext(UserAuthContext);

  useEffect(() => {
    setTimeout(() => {
      if(!accessToken) setLoading(false);
    },250)
  },[loading, accessToken])
  
  if(loading){
    return <LoadingScreen/>
  }else {
    return (
      <div className='text-center'>
        <p className='m-2 text-lg text-neutralDark'>Click below to sign in with your Spotify account.</p>
        <button onClick={login} className="btn rounded-full text-neutralLight bg-neutralDark hover:bg-secondary m-2">Authorize</button>
      </div>
    )
  }
}
  
export default AuthScreen